<template>
  <div>
    <!-- 首图 -->
    <div class="bannerCover">
      <img src="@/assets/img/news/banner-news.png" />
    </div>
    <!-- tab切换 -->
    <div class="tabBox">
      <div @click="handleSwitchChange(0)">公司资讯</div>
      <div @click="handleSwitchChange(1)">行业资讯</div>
      <span :style="`left: ${activeID * 50}%;`" class="tabBox_active"></span>
    </div>
    <!-- 内容列表 -->
    <div>
      <van-swipe
        :loop="false"
        duration="500"
        indicator-color="white"
        :initial-swipe="activeID"
        @change="handleSwitchChange"
      >
        <van-swipe-item v-for="(item, index) in NewsIndustryTotal" :key="index">
          <div class="contentBox">
            <div
              class="NewsIndustry_content"
              @click="goNewsDetails('/newsDetailsMobile', item)"
            >
              <div class="NewsIndustry_contentImg">
                <img :src="item.imgUrl" />
              </div>
              <div class="NewsIndustry_contentInfo">
                <div class="NewsIndustry_contentInfo_subtitle">
                  {{ item.title }}
                </div>
                <div class="NewsIndustry_contentInfo_content">
                  {{ item.info }}
                </div>
                <div class="NewsIndustry_contentInfo_time">
                  {{ item.time }}
                </div>
              </div>
            </div>
            <div class="NewsIndustry_list">
              <div
                class="NewsIndustry_listItem"
                @click="goNewsDetails('/newsDetailsMobile', info)"
                v-for="(info, infoIndex) in item.NewsIndustryList"
                :key="infoIndex"
              >
                <div class="NewsIndustry_listItem_img">
                  <img :src="info.imgUrl" />
                </div>
                <div class="NewsIndustry_listItem_info">
                  <div class="NewsIndustry_listItem_infoTitle">
                    {{ info.title }}
                  </div>
                  <div class="NewsIndustry_listItem_infoContent">
                    {{ info.info }}
                  </div>
                  <div class="NewsIndustry_listItem_infoTime">
                    {{ info.time }}
                  </div>
                </div>
              </div>
            </div>
            <div class="NewsIndustry_page">
              <el-pagination
                layout="prev, pager, next"
                :page-size="3"
                background
                :pager-count="5"
                @current-change="(page) => handleCurrentChange(index, page)"
                :total="item.NewsIndustryLists.length"
              >
              </el-pagination>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import { list, listOne } from "@/api/list";
export default {
  data() {
    return {
      activeID: 0,
      // 行业资讯数据列表-总
      NewsIndustryTotal: [
        {
          imgUrl: require("@/assets/img/home/Warm congratulations on the establishment of Guangzhou Weilin Co., Ltd. 220.png"),
          title: "2021年12月8日",
          time: "2021-12-08 09:00",
          mainTitle: "公司资讯",
          mainETitle: "company information",
          content: `
        <div class="Section0"  style="layout-grid:15.6000pt;" ><p class=MsoNormal  style="text-align:left;" ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
        font-size:18.0000pt;mso-font-kerning:1.0000pt;" ><font face="宋体" ></font></span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        mso-font-kerning:1.0000pt;" ><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        mso-font-kerning:1.0000pt;" >&nbsp;2021年12月8日</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        mso-font-kerning:1.0000pt;" ><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        mso-font-kerning:1.0000pt;" ><font face="宋体" >自</font>2017年微林软件成立以来，历经五年的五年的稳步发展，从专注于视觉检测和视觉工业自动化的企业，逐步向智能化食物管理的的新方向转变,致力于让中国4.6亿的家庭的冰箱实现智能化食物管理，并通过生鲜购物和线上烹饪直播等为家庭提供亲切关怀服务。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        mso-font-kerning:1.0000pt;" ><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        mso-font-kerning:1.0000pt;" >&nbsp;意味着向“2025年，成为视觉领域的知名企业”这一伟大愿景迈进了重大的一步。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="text-align:left;" ><img width="303"  height="538"  src="${require("@/assets/img/home/Warm congratulations on the establishment of Guangzhou Weilin Co., Ltd. 220.png")}" ><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(12,12,12);
        letter-spacing:0.0000pt;text-transform:none;font-style:normal;
        font-size:13.5000pt;mso-font-kerning:1.0000pt;" ><o:p>&nbsp;</o:p></span></p></div>
        `,
          info: "2021年12月8日自2017年微林软件成立以来，历经五年的五年的稳步发展，从专注于视觉检测和视觉工业自动化的企业，逐步向智能化食物管理的的新方向转变, 致力于让中国4.6亿的家庭的冰箱实现智能化食物管理，并通过生鲜购物和线上烹饪直播等为家庭提供亲切关怀服务。意味着向“2025年，成为视觉领域的知名企业”这一伟大愿景迈进了重大的一步。",
          NewsIndustryList: [],
          NewsIndustryLists: listOne,
        },
        {
          mainTitle: "行业资讯",
          mainETitle: "industry information",
          imgUrl: require("@/assets/img/news/20200104115226565.jpeg"),
          title: "目前很多行业为什么要应用机器视觉系统？",
          time: "2020-01-04 11:50",
          info: `
            在工控自动化操纵中应用机器视觉系统有下列五大关键缘故： 1：速率
              ——设备可以迅速的检测商品。非常多的是当检测髙速健身运动的物块时，例如生产网上，设备可以提升生产率。
              2
              ：精确性——由于人的眼睛有物理学标准的约束力，在精确性上设备有明显的优点。就算人的眼睛借助高倍放大镜或光学显微镜来检测提升，设备依然会更加精确，由于它的精密度可以抵达千分之一英尺。
          `,
          content: `
            <div>
    <div class="div-middle">
        <div class="row">
            <div class="col-md-10">
                <span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; 在工控自动化操纵中应用机器视觉系统有下列五大关键缘故：</span><br />
<br />
<span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;1：速率 ——设备可以迅速的检测商品。非常多的是当检测髙速健身运动的物块时，例如生产网上，设备可以提升生产率。</span><br />
<img src="${require("@/assets/img/news/20191126185650_2821.png")}" alt="" /><br />
<span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;2 ：精确性——由于人的眼睛有物理学标准的约束力，在精确性上设备有明显的优点。就算人的眼睛借助高倍放大镜或光学显微镜来检测提升，设备依然会更加精确，由于它的精密度可以抵达千分之一英尺。</span><br />
<br />
<span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;3：成本费 ——由于设备比人快，一台机器视觉检测设备可以担负好自身的重任。而且设备不用终止、不容易生病、可以连续工作中，因此可以巨大的提升生产高效率。</span><br />
<img src="${require("@/assets/img/news/20200104115125_4956.jpg")}" alt="" /><br />
<span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;4：可重复性——设备能能够把一个商品一次一次的开展检测工作中而不容易精神疲惫。再者就是，人的眼睛每一次检测商品时都是有大小的不一样，就算商品是彻底一样的。</span><br />
<br />
<p>
	<span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;5：普遍性——人的眼睛检测还有一个显著的缺陷，亦是人的可变性产生的主观，检测成效会随职工情绪的优劣产生更改，而设备沒有悲喜，检测的結果十分靠谱。</span>
</p>
<p>
	<span style="font-size:16px;"><img src="${require("@/assets/img/news/20200104115145_8628.jpg")}" alt="" /><br />
</span>
</p>
<p>
	<span style="font-size:16px;"><br />
</span>
</p> 
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </div>
</div>
          `,
          // 展示行业资讯列表
          NewsIndustryList: [],
          // 行业资讯数据列表
          NewsIndustryLists: list,
        },
      ],
    };
  },
  created() {
    this.NewsIndustryTotal.map((item) => {
      item.NewsIndustryList = item.NewsIndustryLists.slice(0, 3);
    });
  },
  methods: {
    // 处理切换
    handleSwitchChange(index) {
      this.activeID = index;
    },
    // 处理分页
    handleCurrentChange(index, page) {
      this.$nextTick(() => {
        this.NewsIndustryTotal[index].NewsIndustryList = [
          ...this.NewsIndustryTotal[index].NewsIndustryLists.slice(
            page * 3 - 3,
            page * 3
          ),
        ];
      });
    },
    // 跳转详情
    goNewsDetails(url, item) {
      this.$router.push({
        path: url,
        query: {
          title: item.title,
          time: item.time,
          content: item.content,
        },
      });
    },
  },
};
</script>

<style scoped>
/* 首页 */
.bannerCover {
  width: 100%;
  height: 136px;
}
.bannerCover img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
/* 切换 */
.tabBox {
  font-size: 18px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tabBox > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 20px 0;
}
.tabBox_active {
  bottom: 0;
  width: 50%;
  border-bottom: 2px solid #002780;
  position: absolute;
  transition: all 0.5s;
}
.contentBox {
  width: 100%;
  padding: 40px 20px 0;
  box-sizing: border-box;
}
/* 行业资讯-内容 */
.NewsIndustry_content {
  padding: 0 18px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
}
.NewsIndustry_contentImg {
  width: 100%;
  height: 200px;
}
.NewsIndustry_contentImg img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
.NewsIndustry_contentInfo {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.NewsIndustry_contentInfo_subtitle {
  font-size: 18px;
  /* font-weight: 600; */
  color: #333;
  line-height: 20px;
  margin-top: 12px;
}
.NewsIndustry_contentInfo_content {
  font-size: 12px;
  font-weight: 400;
  color: #b3b3b3;
  margin-top: 6px;
  line-height: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-indent: 2em;
}
.NewsIndustry_contentInfo_time {
  font-size: 12px;
  font-weight: 400;
  color: #cccccc;
  margin-top: 8px;
}
/* 公司资讯-列表 */
.NewsIndustry_listItem {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.NewsIndustry_listItem_info {
  width: 100%;
  margin-left: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.NewsIndustry_listItem_infoTitle {
  font-size: 18px;
  /* font-weight: 600; */
  color: #333333;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.NewsIndustry_listItem_infoContent {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-indent: 2em;
  margin-top: 6px;
}
.NewsIndustry_listItem_infoTime {
  font-size: 12px;
  font-weight: 400;
  color: #cccccc;
  line-height: 18px;
  margin-top: 8px;
}
.NewsIndustry_listItem_img {
  width: 40%;
  height: 112px;
}
.NewsIndustry_listItem_img img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
/* 公司资讯-分页 */
.NewsIndustry_page {
  width: 100%;
  padding: 0 20px;
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.el-pagination button:disabled {
  background-color: #f5f5f5;
}
.el-dialog,
.el-pager li {
  background-color: #f5f5f5;
}
.el-pagination .btn-next,
.el-pagination .btn-prev {
  background-color: #f5f5f5;
}
</style>